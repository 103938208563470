import { useUpdateBusinessProfile } from '@paid-ui/blocks/settings/mutations/update-business-profile';
import { Button } from '@paid-ui/components/button';
import { DrawerClose } from '@paid-ui/components/drawer';
import {
  BusinessStructure,
  businessStructures,
  BusinessType,
  defaultAddress,
} from '@paid-ui/constants';
import { SVGIcon } from '@paid-ui/icons';
import { closeDialog } from '@paid-ui/models/dialog';
import { featureManager } from '@paid-ui/models/feature';
import { userManager } from '@paid-ui/models/user';
import { abnPattern, acnPattern } from '@paid-ui/regexps';
import { addressSchema, businessNameSchema, userNameSchema } from '@paid-ui/schemas';
import { type Address, type UserInfoForBusiness } from '@paid-ui/types';
import {
  Checkbox,
  Input,
  Logo,
  PlaceAutocomplete,
  RadioGroup,
  RcInputNumber,
  Separator,
  useToast,
} from '@paid-ui/ui';
import { formatAddress } from '@paid-ui/utils';
import { FieldArray, type FieldArrayRenderProps, FormikProvider, useFormik } from 'formik';
import { omit } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useSnapshot } from 'valtio/react';
import * as yup from 'yup';

import {
  addButtonVariants,
  CompanySignatoriesCard,
  CompanySignatoriesCardMilestoneOne,
  DeleteButton,
  DeleteIcon,
  gridVariants,
} from './_Base';

const validationSchema = yup.object({
  earlyReleaseToggleOn: yup.boolean(),
  provideBuildingServices: yup.boolean(),
  businessStructure: yup.string(),
  hasMultipleUsers: yup.boolean(),
  currentUser: yup.object({
    id: yup.string(),
    firstName: userNameSchema,
    lastName: userNameSchema,
    email: yup.string().required().email().label('Email address'),
  }),
  abn: yup.string().required('ABN is required').matches(abnPattern, {
    message: 'ABN must be 11 digits',
    excludeEmptyString: true,
  }),
  acn: yup.string().when(['businessStructure'], {
    is: (businessStructure: BusinessStructure) => businessStructure === BusinessStructure.COMPANY,
    then: (schema) =>
      schema.required('ACN is required').matches(acnPattern, {
        message: 'ACN must be 9 digits',
        excludeEmptyString: true,
      }),
  }),
  contactNumber: yup.string(),
  registeredForGst: yup.boolean().required('Required field'),
  registeredAddress: addressSchema.required('Business address is required'),
  registeredName: businessNameSchema,
  builderLicenceNumber: yup.string().when('enterAsABuilder', {
    is: true,
    then: (schema) => schema.required('Required field'),
  }),
  earlyReleaseDiscountRate: yup
    .number()
    .positive()
    .min(0.01)
    .max(100)
    .label('Discount')
    .when(['earlyReleaseToggleOn', 'provideBuildingServices'], {
      is: (earlyReleaseToggleOn: boolean, provideBuildingServices: boolean) =>
        earlyReleaseToggleOn && provideBuildingServices,
      then: (schema) => schema.required('Required field'),
    }),
  additionalUsers: yup
    .array(
      yup.object({
        firstName: userNameSchema,
        lastName: userNameSchema,
        email: yup.string().required().email().label('Email address'),
      }),
    )
    .when(['hasMultipleUsers', 'businessStructure'], {
      is: (hasMultipleUsers: boolean, businessStructure: BusinessStructure) =>
        businessStructure === BusinessStructure.COMPANY && hasMultipleUsers,
      then: (schema) => schema.min(1),
    }),
});

const initialState = {
  earlyReleaseToggleOn: false,
  provideBuildingServices: false,
  businessStructure: undefined as BusinessStructure | undefined,
  registeredName: '',
  registeredAddress: defaultAddress as Address | undefined,
  acn: '',
  abn: '',
  contactNumber: '',
  rateSetupCompleted: false,
  registeredForGst: undefined as boolean | undefined,
  enterAsABuilder: false,
  builderLicenceNumber: '',
  earlyReleaseDiscountRate: undefined as number | undefined,
  currentUser: undefined as UserInfoForBusiness | undefined,
  additionalUsers: [] as UserInfoForBusiness[],
};

const initSignatories = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
};

export const EditBusinessInfo = () => {
  const toast = useToast();
  const { enableEarlyRelease } = useSnapshot(featureManager);
  const addButtonRef = useRef<HTMLButtonElement | null>(null);

  const { mutate, isLoading } = useUpdateBusinessProfile();

  const {
    abn,
    acn,
    contactNumber,
    profile,
    business,
    isTrust,
    currentUser,
    hasBusiness,
    businessType,
    registeredName,
    additionalUsers,
    hasMultipleUsers,
    registeredForGst,
    registeredAddress,
    isKybNoFailRequested,
    businessStructure,
    builderLicenceNumber,
    businessSetupCompleted,
    provideBuildingServices,
    earlyReleaseDiscountRate,
  } = useSnapshot(userManager);

  const formik = useFormik({
    initialValues: initialState,
    validationSchema,
    onSubmit(values) {
      if (isLoading) {
        return;
      }

      const {
        businessStructure,
        registeredName,
        registeredAddress,
        abn,
        acn,
        contactNumber,
        earlyReleaseDiscountRate,
        registeredForGst,
        additionalUsers,
        enterAsABuilder,
        builderLicenceNumber,
      } = values;

      const cleanedEarlyReleaseDiscountRate = earlyReleaseDiscountRate
        ? Math.round(Number(earlyReleaseDiscountRate) * 100) / 10_000
        : earlyReleaseDiscountRate;

      const basicParams = {
        businessId: business?.id ?? '',
        abn: abn.trim(),
        contactNumber: contactNumber.trim(),
        registeredName: registeredName.trim(),
        registeredAddress: omit(registeredAddress, ['manual']),
        earlyReleaseDiscountRate: cleanedEarlyReleaseDiscountRate,
        builderLicenceNumber: undefined as string | undefined,
        registeredForGst: undefined as boolean | undefined,
      };

      if (provideBuildingServices) {
        if (enterAsABuilder && builderLicenceNumber.trim()) {
          basicParams.builderLicenceNumber = builderLicenceNumber.trim();
        }
        basicParams.registeredForGst = registeredForGst;
      }
      const additionalDirectors = additionalUsers.map((user) => {
        return {
          firstName: user.firstName.trim(),
          lastName: user.lastName.trim(),
          email: user.email.trim(),
        };
      });

      if (isTrust) {
        mutate(basicParams);
      } else {
        mutate({
          ...basicParams,
          additionalUsers: additionalDirectors,
          acn: businessStructure === BusinessStructure.COMPANY ? acn.trim() : undefined,
        });
      }
    },
  });

  const handleBusinessAddressTouched = useCallback(() => {
    formik.setFieldTouched('businessAddress', !!formik.errors.registeredAddress);
  }, [formik]);

  const handleSubmit = useCallback(() => {
    formik.submitForm();
  }, [formik]);

  useEffect(() => {
    if (profile) {
      formik.setValues({
        earlyReleaseToggleOn: enableEarlyRelease,
        provideBuildingServices,
        businessStructure,
        registeredName,
        abn,
        acn,
        contactNumber,
        registeredAddress,
        earlyReleaseDiscountRate,
        rateSetupCompleted: !!earlyReleaseDiscountRate,
        registeredForGst,
        builderLicenceNumber,
        enterAsABuilder: !!builderLicenceNumber,
        currentUser,
        additionalUsers,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.id]);

  useEffect(() => {
    if (!hasBusiness) {
      toast.error('No permission');
      closeDialog();
    }
  }, [hasBusiness, toast]);

  const getSignatoriesErrors = useCallback(
    (itemIndex: number, field: keyof typeof initSignatories) => {
      return formik.touched.additionalUsers?.[itemIndex]?.[field]
        ? // @ts-expect-error missing types
          formik.errors.additionalUsers?.[itemIndex]?.[field]
        : undefined;
    },
    [formik.errors.additionalUsers, formik.touched.additionalUsers],
  );

  const renderSignatories = useCallback(
    (user: UserInfoForBusiness, helper: FieldArrayRenderProps, index: number) => {
      return (
        <CompanySignatoriesCard key={user.id}>
          <Checkbox name="isChecked" value={true} css={{ gridArea: 'checkbox' }} />
          <Input
            block
            required
            name="user.firstName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            css={{ gridArea: 'firstName' }}
            value={user.firstName}
            placeholder="First name *"
            errorMessage={getSignatoriesErrors(index, 'firstName')}
          />
          <Input
            block
            required
            name="user.lastName"
            css={{ gridArea: 'lastName' }}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={user.lastName}
            placeholder="Last name *"
            errorMessage={getSignatoriesErrors(index, 'lastName')}
          />
          <Input
            block
            required
            disabled
            name="role"
            css={{ gridArea: 'role' }}
            value={'Director'}
          />
          <DeleteButton
            type="button"
            hidden={formik.values.additionalUsers?.length <= 1 || businessSetupCompleted}
          >
            <DeleteIcon name="delete" onClick={() => helper.remove(index)} />
          </DeleteButton>
        </CompanySignatoriesCard>
      );
    },
    [
      businessSetupCompleted,
      formik.handleBlur,
      formik.handleChange,
      formik.values.additionalUsers?.length,
      getSignatoriesErrors,
    ],
  );

  const renderAdditionalSignatories = useCallback(() => {
    return (
      <div className="relative grid w-full items-start gap-x-[14px] gap-y-2.5 rounded-md border border-grey-mid bg-grey-light sm:grid-cols-[160px_160px_1fr] sm:gap-2.5">
        <Input
          block
          required
          disabled={businessSetupCompleted}
          css={{ gridArea: 'firstName' }}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          name="additionalUsers.0.firstName"
          value={formik.values.additionalUsers?.[0]?.firstName}
          placeholder="First name *"
          errorMessage={getSignatoriesErrors(0, 'firstName')}
        />
        <Input
          block
          required
          name="additionalUsers.0.lastName"
          css={{ gridArea: 'lastName' }}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          disabled={businessSetupCompleted}
          value={formik.values.additionalUsers?.[0]?.lastName}
          placeholder="Last name *"
          errorMessage={getSignatoriesErrors(0, 'lastName')}
        />
        <Input block required disabled name="role" css={{ gridArea: 'role' }} value={'Director'} />
        <Input
          block
          required
          name="additionalUsers.0.email"
          css={{ gridArea: 'email' }}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          disabled={businessSetupCompleted}
          value={formik.values.additionalUsers?.[0]?.email}
          placeholder="Email *"
          errorMessage={getSignatoriesErrors(0, 'email')}
        />
      </div>
    );
  }, [
    businessSetupCompleted,
    formik.handleBlur,
    formik.handleChange,
    formik.values.additionalUsers,
    getSignatoriesErrors,
  ]);

  const handleAddSignatories = useCallback((helper: FieldArrayRenderProps) => {
    helper.push(initSignatories);
    setTimeout(() => {
      addButtonRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }, 10);
  }, []);

  return (
    <div className="flex h-full w-full flex-col overflow-y-auto">
      <header className="sticky top-0 z-10 flex w-full shrink-0 flex-col items-center justify-center gap-[14px] border-b border-grey-mid bg-white p-5 sm:rounded-t-2xl">
        <DrawerClose />
        <div className="flex items-center gap-2">
          <Logo symbolOnly width={25} height={25} />
          <div className="text-center text-xl font-semibold">Edit business information</div>
        </div>
      </header>
      <div className="flex flex-1 flex-col gap-6 px-5 py-[18px]">
        <form
          className="flex w-full flex-col gap-6"
          onSubmit={formik.handleSubmit}
          onReset={formik.handleReset}
        >
          <div className="relative grid w-full grid-cols-1 gap-y-9 sm:grid-cols-2 sm:gap-x-5">
            <RadioGroup
              showIcon
              required
              disabled
              classic={false}
              label="Type"
              labelSize={16}
              labelWeight="medium"
              name="businessStructure"
              value={formik.values.businessStructure}
              onChange={(value) => formik.setFieldValue('businessStructure', value)}
              options={Object.values(BusinessStructure)
                .filter((value) => value !== BusinessStructure.OTHER)
                .map((value) => ({
                  value,
                  label: businessStructures[value],
                  color: 'primary',
                  hidden: value !== formik.values.businessStructure,
                }))}
            />
          </div>
          <Input
            required
            size="large"
            maxLength={60}
            labelSize={16}
            readOnly={isKybNoFailRequested}
            labelWeight="medium"
            name="registeredName"
            label="Business name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.registeredName}
            errorMessage={
              formik.touched.registeredName && Boolean(formik.errors.registeredName)
                ? formik.errors.registeredName
                : undefined
            }
            block
          />
          <div className="relative grid w-full grid-cols-1 gap-y-9 sm:grid-cols-2 sm:gap-x-5">
            <Input
              block
              required
              name="abn"
              label="ABN"
              size="large"
              labelSize={16}
              inputMode="numeric"
              readOnly={isKybNoFailRequested}
              labelWeight="medium"
              format="## ### ### ###"
              value={formik.values.abn}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.abn && Boolean(formik.errors.abn) ? formik.errors.abn : undefined
              }
            />
            <Input
              block
              required
              name="acn"
              label="ACN"
              size="large"
              labelSize={16}
              hidden={isTrust || businessStructure !== BusinessStructure.COMPANY}
              labelWeight="medium"
              inputMode="numeric"
              readOnly={isKybNoFailRequested}
              format="### ### ###"
              value={formik.values.acn}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.acn && Boolean(formik.errors.acn) ? formik.errors.acn : undefined
              }
            />
          </div>
          <PlaceAutocomplete
            required
            size="large"
            maxLength={60}
            preview={false}
            label="Business address"
            name="registeredAddress"
            value={formik.values.registeredAddress}
            placeholder="Please enter keyword to search address"
            onChange={(value) => formik.setFieldValue('registeredAddress', value)}
            onBlur={handleBusinessAddressTouched}
            touched={formik.touched.registeredAddress}
            errorMessage={formik.errors.registeredAddress}
          />
          <Input
            block
            name="contactNumber"
            label="Contact number"
            size="large"
            labelSize={16}
            labelWeight="medium"
            inputMode="numeric"
            value={formik.values.contactNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.touched.contactNumber && Boolean(formik.errors.contactNumber)
                ? formik.errors.contactNumber
                : undefined
            }
          />
          <RadioGroup
            showIcon
            required
            disabled
            labelSize={16}
            classic={false}
            labelWeight="medium"
            name="registeredForGst"
            hidden={!provideBuildingServices}
            label="Is this business registered for GST?"
            value={
              formik.values.registeredForGst === undefined
                ? ''
                : formik.values.registeredForGst
                ? '1'
                : '0'
            }
            onChange={(value) => formik.setFieldValue('registeredForGst', value === '1')}
          />
          <div
            className={gridVariants({
              hidden: !provideBuildingServices || businessType !== BusinessType.BUILDER,
            })}
          >
            <div className="flex items-center gap-6">
              <div className="shrink-0 text-xl font-semibold">Licensing</div>
              <Separator decorative />
            </div>
            <RadioGroup
              showIcon
              required
              classic={false}
              labelSize={16}
              labelWeight="medium"
              name="enterAsABuilder"
              value={formik.values.enterAsABuilder ? '1' : '0'}
              label="Can this business enter domestic building contracts as a builder?"
              onChange={(value) => formik.setFieldValue('enterAsABuilder', value === '1')}
            />
            <Input
              required
              size="large"
              width={'50%'}
              labelSize={16}
              maxLength={30}
              inputMode="numeric"
              labelWeight="medium"
              name="builderLicenceNumber"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label="Builder Licence Number"
              hidden={!formik.values.enterAsABuilder}
              value={formik.values.builderLicenceNumber}
              errorMessage={
                formik.touched.builderLicenceNumber && Boolean(formik.errors.builderLicenceNumber)
                  ? formik.errors.builderLicenceNumber
                  : undefined
              }
              block
            />
          </div>
          <div
            className={gridVariants({
              hidden: !enableEarlyRelease || !provideBuildingServices,
            })}
          >
            <div className="flex items-center gap-6">
              <div className="shrink-0 text-xl font-semibold">EarlyRelease discount</div>
              <Separator decorative />
            </div>
            <div className="text-base text-grey-dark">
              EarlyRelease allows you to pay claims before the due date in exchange for a discount
              on the associated claim. The payee can either accept or negotiate your default
              discount %.
            </div>
            <div className="relative grid w-full grid-cols-1 gap-y-9 sm:grid-cols-2 sm:gap-x-5">
              <RcInputNumber
                block
                required
                size="large"
                labelSize={16}
                precision={2}
                mode="percentage"
                trailingBordered
                trailingText="%"
                placeholder="0.0"
                labelWeight="medium"
                onBlur={formik.handleBlur}
                name="earlyReleaseDiscountRate"
                label="Set a default claim discount %"
                value={formik.values.earlyReleaseDiscountRate || ('' as unknown as number)}
                onChange={(value) => formik.setFieldValue('earlyReleaseDiscountRate', value ?? 0)}
                errorMessage={
                  formik.touched.earlyReleaseDiscountRate &&
                  Boolean(formik.errors.earlyReleaseDiscountRate)
                    ? formik.errors.earlyReleaseDiscountRate
                    : undefined
                }
              />
            </div>
          </div>
          <div
            className={gridVariants({
              hidden: businessStructure !== BusinessStructure.COMPANY,
            })}
          >
            <div className="flex items-center gap-6">
              <div className="shrink-0 text-xl font-semibold">Company signatories</div>
              <Separator decorative />
            </div>
            <div className="hidden text-base text-grey-dark">
              Unless you are both a company director and secretary an additional signatory must be
              added.
            </div>
            <div className="grid gap-2">
              <span className="text-base font-medium">Me</span>
              <CompanySignatoriesCardMilestoneOne>
                {/* <Checkbox name="isChecked" value={true} disabled css={{ gridArea: 'checkbox' }} /> */}
                <Input
                  block
                  required
                  disabled
                  name="firstName"
                  css={{ gridArea: 'firstName' }}
                  value={formik.values.currentUser?.firstName}
                />
                <Input
                  block
                  required
                  disabled
                  name="lastName"
                  css={{ gridArea: 'lastName' }}
                  value={formik.values.currentUser?.lastName}
                />
                <Input
                  block
                  required
                  disabled
                  name="role"
                  css={{ gridArea: 'role' }}
                  value={hasMultipleUsers ? 'Director and secretory' : 'Director'}
                />
              </CompanySignatoriesCardMilestoneOne>
            </div>
            <div
              className={gridVariants({
                compact: true,
                hidden: !hasMultipleUsers,
              })}
            >
              <span className="text-base font-medium">Current additional signatory</span>
              {renderAdditionalSignatories()}
            </div>
            <div className="hidden">
              <FormikProvider value={formik}>
                <FieldArray
                  name="additionalUsers"
                  render={(helper) => (
                    <div className="flex w-full list-none flex-col gap-2">
                      {formik.values.additionalUsers?.map((user, index) =>
                        renderSignatories(user, helper, index),
                      )}
                      <Button
                        className={addButtonVariants({
                          hidden:
                            formik.values.additionalUsers.length >= 2 || businessSetupCompleted,
                        })}
                        ref={addButtonRef}
                        onClick={() => handleAddSignatories(helper)}
                      >
                        Add signatories
                        <SVGIcon name="create" />
                      </Button>
                    </div>
                  )}
                />
              </FormikProvider>
            </div>
          </div>
        </form>
      </div>
      <footer className="sticky bottom-0 z-10 flex w-full shrink-0 items-center justify-center bg-white p-2.5">
        <div className="space-x-2.5 rounded-full bg-white p-1.5 shadow-lg">
          <Button
            variant="solid"
            pill
            full={false}
            onClick={handleSubmit}
            disabled={!formik.isValid || !formatAddress(formik.values.registeredAddress)}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </footer>
    </div>
  );
};
