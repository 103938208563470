import { closeNestedFlyout } from '@paid-ui/blocks/global-flyout';
import { dialogManager } from '@paid-ui/models/dialog';
import { reloadProfile, userManager } from '@paid-ui/models/user';
import { type Address } from '@paid-ui/schemas/zod/address';
import { useToast } from '@paid-ui/ui/toast';
import { request } from '@paid-ui/utils/axios';
import { useMutation } from '@tanstack/react-query';
import { omitBy } from 'lodash';
import { useSnapshot } from 'valtio/react';

import { runAfterSave } from '../_utils';

export type UpdateAdditionalUsersBody = {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile?: string;
  address?: Address;
};

export const useUpdateAdditionalUsers = () => {
  const toast = useToast();
  const { userGroupId, userGroupRelation } = useSnapshot(userManager);
  const { stack } = useSnapshot(dialogManager);

  const id = userGroupId; // company trustee also uses this id
  return useMutation({
    mutationKey: ['UpdateAdditionalUsers', id],
    mutationFn: async (body: UpdateAdditionalUsersBody) => {
      await request.post<null>(`/user-group/${id}/users`, {
        userRelation: userGroupRelation,
        setDefaultGroup: true,
        user: omitBy(body, (v) => v === ''),
      });
      return null;
    },
    onSuccess: () => {
      toast.success('Updated successfully.');
      runAfterSave(stack);
    },
    onError: (err) => {
      toast.error(err instanceof Error ? err.message : 'Failed to update information.');
    },
  });
};
