import { dialogManager } from '@paid-ui/models/dialog';
import { type Address } from '@paid-ui/types';
import { useToast } from '@paid-ui/ui/toast';
import { request } from '@paid-ui/utils/axios';
import { useMutation } from '@tanstack/react-query';
import { useSnapshot } from 'valtio/react';

import { runAfterSave } from '../_utils';

type UpdatePersonalInfoBody = {
  id: string;
  firstName?: string;
  lastName?: string;
  homeAddress?: Address;
};

type UpdatePersonalInfoResponse<T = undefined> = {
  data?: T;
};

export const useUpdatePersonalInfo = () => {
  const toast = useToast();
  const { stack } = useSnapshot(dialogManager);

  return useMutation({
    retry: false,
    mutationFn: async (body: UpdatePersonalInfoBody) => {
      const { id, ...rest } = body;
      const { data } = await request.put<UpdatePersonalInfoResponse>(`/users/${id}`, rest);
      return data;
    },
    onSuccess() {
      toast.success('Updated successfully.');
      runAfterSave(stack);
    },
    onError(err) {
      toast.error(err instanceof Error ? err.message : 'Failed to update personal information');
    },
  });
};
