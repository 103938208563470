import { closeDialog, type DialogState, restorePreviousDialog } from '@paid-ui/models/dialog';
import { reloadProfile } from '@paid-ui/models/user';

import { closeNestedFlyout } from '../global-flyout';

export const runAfterSave = (stack: DialogState[]) => {
  reloadProfile();
  if (stack.length > 0) {
    restorePreviousDialog();
  } else {
    closeDialog();
    closeNestedFlyout();
  }
};
