import { useUpdatePersonalInfo } from '@paid-ui/blocks/settings/mutations/update-personal-info';
import { Button } from '@paid-ui/components/button';
import { DrawerClose } from '@paid-ui/components/drawer';
import { defaultAddress } from '@paid-ui/constants';
import { userManager } from '@paid-ui/models/user';
import { mobileNumberPattern } from '@paid-ui/regexps';
import { userNameSchema } from '@paid-ui/schemas';
import { type API } from '@paid-ui/types';
import { Input, Logo, PlaceAutocomplete } from '@paid-ui/ui';
import { formatAddress } from '@paid-ui/utils';
import { useFormik } from 'formik';
import { omit, omitBy } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useSnapshot } from 'valtio/react';
import * as yup from 'yup';

export const EditPersonalInfo = () => {
  const { profile, isKycNoFailRequested } = useSnapshot(userManager);
  const { mutate, isLoading } = useUpdatePersonalInfo();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      homeAddress: defaultAddress,
    },
    validationSchema: yup.object({
      firstName: userNameSchema,
      lastName: userNameSchema,
      email: yup
        .string()
        .required('Email address is required.')
        .email('Wrong email address format.'),
      mobile: yup.string().matches(mobileNumberPattern, {
        excludeEmptyString: true,
        message: 'Wrong mobile number format.',
      }),
    }),
    onSubmit(values) {
      if (!profile || isLoading) {
        return;
      }

      const { homeAddress, ...restValues } = values;

      mutate({
        id: profile.id,
        homeAddress: formatAddress(homeAddress) ? omit(homeAddress, ['manual']) : undefined,
        ...(omitBy(restValues, (value) => value === '') as API.UpdateUserProfileBody),
      });
    },
  });

  const handleHomeAddressTouched = useCallback(() => {
    formik.setFieldTouched('homeAddress', !!formik.errors.homeAddress);
  }, [formik]);

  const handleSubmit = useCallback(() => {
    formik.submitForm();
  }, [formik]);

  useEffect(() => {
    if (profile) {
      formik.setValues({
        firstName: profile?.firstName || '',
        lastName: profile?.lastName || '',
        email: profile?.email || '',
        mobile: profile?.mobile || '',
        // @ts-expect-error the same address shape
        homeAddress: profile.homeAddress ?? defaultAddress,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.id]);

  return (
    <div className="flex h-full w-full flex-col">
      <header className="sticky top-0 z-10 flex w-full shrink-0 flex-col items-center justify-center gap-[14px] border-b border-grey-mid bg-white p-5 sm:rounded-t-2xl">
        <DrawerClose />
        <div className="flex items-center gap-2">
          <Logo symbolOnly width={25} height={25} />
          <div className="text-center text-xl font-semibold">Edit personal information</div>
        </div>
      </header>
      <div className="flex w-full flex-1 justify-center gap-6 px-[18px] py-5">
        <form
          className="flex w-full flex-col gap-6"
          onSubmit={formik.handleSubmit}
          onReset={formik.handleReset}
        >
          <div className="relative grid w-full grid-cols-1 gap-y-9 sm:grid-cols-2 sm:gap-x-5">
            <Input
              block
              required
              size="large"
              labelSize={16}
              maxLength={30}
              name="firstName"
              label="First name"
              labelWeight="medium"
              disabled={isKycNoFailRequested}
              autoComplete="given-name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.firstName}
              helperTextStyle={formik.errors.firstName ? {} : { color: '#A6ACB3' }}
              helperText={isKycNoFailRequested ? 'Contact support if name need to be updated' : ''}
              errorMessage={
                formik.touched.firstName && Boolean(formik.errors.firstName)
                  ? formik.errors.firstName
                  : undefined
              }
            />
            <Input
              block
              required
              size="large"
              labelSize={16}
              maxLength={30}
              name="lastName"
              label="Last Name"
              labelWeight="medium"
              disabled={isKycNoFailRequested}
              autoComplete="family-name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.lastName}
              helperTextStyle={formik.errors.lastName ? {} : { color: '#A6ACB3' }}
              helperText={isKycNoFailRequested ? 'Contact support if name need to be updated' : ''}
              errorMessage={
                formik.touched.lastName && Boolean(formik.errors.lastName)
                  ? formik.errors.lastName
                  : undefined
              }
            />
          </div>
          <PlaceAutocomplete
            size="large"
            maxLength={60}
            preview={false}
            name="homeAddress"
            label="Home address"
            value={formik.values.homeAddress}
            onBlur={handleHomeAddressTouched}
            touched={formik.touched.homeAddress}
            errorMessage={formik.errors.homeAddress}
            placeholder="Please enter keyword to search address"
            onChange={(value) => formik.setFieldValue('homeAddress', value)}
          />
        </form>
      </div>
      <footer className="sticky bottom-0 flex w-full shrink-0 items-center justify-center bg-white p-2.5">
        <div className="space-x-2.5 rounded-full bg-white p-1.5 shadow-lg">
          <Button variant="solid" pill full={false} onClick={handleSubmit}>
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </footer>
    </div>
  );
};
