import { PaymentStageName } from '@paid-ui/constants';
import * as yup from 'yup';

export const paymentStageSchema = yup.object({
  stage: yup
    .string()
    .required('Stage name is required')
    .oneOf(
      Object.values(PaymentStageName),
      `Stage name should be one of ${Object.values(PaymentStageName).join(', ')}`,
    ),
  percentOfContractSum: yup
    .number()
    .required('Percent of contract sum is required')
    .positive('Percent of contract sum must be positive')
    .max(100, 'Percent of contract sum must not be more than 100'),
  customName: yup
    .string()
    .when('stage', {
      is: (value: PaymentStageName) => value === PaymentStageName.OTHER,
      then: (schema) => schema.required('Stage name is required'),
    })
    .max(30, 'Custom stage name must not exceed 30'),
  description: yup.string().max(50, 'Stage description must not exceed 50'),
  mostRelatesTo: yup.string().when('stage', {
    is: (value: PaymentStageName) => value === PaymentStageName.OTHER,
    then: (schema) => schema.required('Required'),
  }),
});

export const paymentStageSchema1 = yup.object({
  stage: yup
    .string()
    .required('Required field')
    .oneOf(
      Object.values(PaymentStageName),
      `Stage name should be one of ${Object.values(PaymentStageName).join(', ')}`,
    ),
  percentOfContractSum: yup
    .number()
    .required('Percent of contract sum is required')
    .positive('Percent of contract sum must be positive')
    .max(100, 'Percent of contract sum must not be more than 100')
    .when('stage', {
      is: (value: PaymentStageName) => value === PaymentStageName.DEPOSIT,
      then: (schema) => schema.max(5, 'Deposit % must be less than or equal 5%'),
    }),
  customName: yup
    .string()
    .when('stage', {
      is: (value: PaymentStageName) => value === PaymentStageName.OTHER,
      then: (schema) => schema.required('Required field'),
    })
    .max(30, 'Custom stage name must not exceed 30'),
  description: yup.string().max(50, 'Stage description must not exceed 50'),
  mostRelatesTo: yup.string().when('stage', {
    is: (value: PaymentStageName) => value === PaymentStageName.OTHER,
    then: (schema) => schema.required('Required field'),
  }),
});

export const paymentStageSchema2 = yup.object({
  stage: yup
    .string()
    .required('Required field')
    .oneOf(
      Object.values(PaymentStageName),
      `Stage name should be one of ${Object.values(PaymentStageName).join(', ')}`,
    ),
  percentOfContractSum: yup
    .number()
    .required('Percent of contract sum is required')
    .positive('Percent of contract sum must be positive')
    .max(100, 'Percent of contract sum must not be more than 100')
    .when('stage', {
      is: (value: PaymentStageName) => value === PaymentStageName.DEPOSIT,
      then: (schema) => schema.max(10, 'Deposit % must be less than or equal 10%'),
    }),
  customName: yup
    .string()
    .when('stage', {
      is: (value: PaymentStageName) => value === PaymentStageName.OTHER,
      then: (schema) => schema.required('Required field'),
    })
    .max(30, 'Custom stage name must not exceed 30'),
  description: yup.string().max(50, 'Stage description must not exceed 50'),
  mostRelatesTo: yup.string().when('stage', {
    is: (value: PaymentStageName) => value === PaymentStageName.OTHER,
    then: (schema) => schema.required('Required field'),
  }),
});

export const paymentStageForSubSupplySchema = yup.object({
  stage: yup.string().required('Required field').max(30, 'Stage name must not exceed 30'),
  percentOfContractSum: yup
    .number()
    .required('Required field')
    .positive('Percent of contract sum must be positive')
    .max(100, 'Claim percentage must be less than or equal 100')
    .nullable(),
  amount: yup.number().min(0, 'Claim amount must be positive').nullable(),
});
