import { useUpdateTrusteesInfo } from '@paid-ui/blocks/settings/mutations/update-trustees-info';
import { Button } from '@paid-ui/components/button';
import { DrawerClose } from '@paid-ui/components/drawer';
import { SVGIcon } from '@paid-ui/icons';
import { userManager } from '@paid-ui/models/user';
import { userNameSchema } from '@paid-ui/schemas';
import type { UserForBusiness } from '@paid-ui/types';
import { Input, Label, Logo } from '@paid-ui/ui';
import { FieldArray, type FieldArrayRenderProps, FormikProvider, useFormik } from 'formik';
import { useCallback, useEffect, useRef } from 'react';
import { useSnapshot } from 'valtio/react';
import * as yup from 'yup';

import { addButtonVariants, DeleteButton, DeleteIcon, IndividualTrusteeCard } from './_Base';

const initSignatories = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
};

export const EditIndividualTrustees = () => {
  const addButtonRef = useRef<HTMLButtonElement | null>(null);

  const { profile, business, isKybKycNoFailRequested, currentUser, additionalUsers } =
    useSnapshot(userManager);
  const { mutate, isLoading } = useUpdateTrusteesInfo();

  const formik = useFormik({
    initialValues: {
      additionalTrustees: [] as UserForBusiness[] | [],
    },
    validationSchema: yup.object({
      additionalTrustees: yup.array(
        yup.object({
          firstName: userNameSchema,
          lastName: userNameSchema,
          email: yup.string().required().email().label('Email address'),
        }),
      ),
    }),
    onSubmit(values) {
      if (!business?.id) return;

      mutate({
        trustId: business.id,
        additionalTrustees: values.additionalTrustees.map((user) => {
          return {
            firstName: user.firstName.trim(),
            lastName: user.lastName.trim(),
            email: user.email.trim(),
          };
        }),
      });
    },
  });

  const handleAddSignatories = useCallback((helper: FieldArrayRenderProps) => {
    helper.push(initSignatories);
    setTimeout(() => {
      addButtonRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }, 10);
  }, []);

  const getSignatoriesErrors = useCallback(
    (itemIndex: number, field: keyof typeof initSignatories) => {
      return formik.touched.additionalTrustees?.[itemIndex]?.[field]
        ? // @ts-expect-error missing types
          formik.errors.additionalTrustees?.[itemIndex]?.[field]
        : undefined;
    },
    [formik.errors.additionalTrustees, formik.touched.additionalTrustees],
  );

  const handleSubmit = useCallback(() => {
    formik.submitForm();
  }, [formik]);

  useEffect(() => {
    if (profile) {
      formik.setValues({
        additionalTrustees: additionalUsers.map((user) => {
          return {
            id: user.id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
          };
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalUsers?.length, profile?.id]);
  return (
    <div className="flex h-full w-full flex-col overflow-y-auto">
      <header className="sticky top-0 z-10 flex w-full shrink-0 flex-col items-center justify-center gap-[14px] border-b border-grey-mid bg-white p-5 sm:rounded-t-2xl">
        <DrawerClose />
        <div className="flex items-center gap-2">
          <Logo symbolOnly width={25} height={25} />
          <div className="text-center text-xl font-semibold">Edit trustees</div>
        </div>
      </header>
      <div className="flex flex-1 flex-col gap-6 px-5 py-[18px]">
        <div className="grid gap-9">
          <Label size={22}>Individual trustees</Label>
          <FormikProvider value={formik}>
            <FieldArray
              name="additionalTrustees"
              render={(helper) => (
                <div className="grid gap-9">
                  {[currentUser, ...formik.values.additionalTrustees]?.map((user, index) => (
                    <div className="grid gap-2" key={index}>
                      <div className="flex items-center gap-1">
                        <span className="text-base font-medium">Trustee {index + 1}</span>
                        {index === 0 ? (
                          <span className="text-base text-grey-dark">(Me)</span>
                        ) : null}
                      </div>
                      <IndividualTrusteeCard>
                        <Input
                          block
                          required
                          name={`additionalTrustees.${index - 1}.firstName`}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          css={{ gridArea: 'firstName' }}
                          value={user?.firstName}
                          placeholder="First name *"
                          disabled={!!user?.id}
                          errorMessage={getSignatoriesErrors(index - 1, 'firstName')}
                        />
                        <Input
                          block
                          required
                          css={{ gridArea: 'lastName' }}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={user?.lastName}
                          placeholder="Last name *"
                          disabled={!!user?.id}
                          name={`additionalTrustees.${index - 1}.lastName`}
                          errorMessage={getSignatoriesErrors(index - 1, 'lastName')}
                        />
                        <Input
                          block
                          required
                          hidden={index === 0}
                          css={{ gridArea: 'email' }}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          disabled={!!user?.id}
                          value={user?.email}
                          placeholder="Email *"
                          name={`additionalTrustees.${index - 1}.email`}
                          errorMessage={getSignatoriesErrors(index - 1, 'email')}
                        />
                        <DeleteButton type="button" hidden={index === 0} disabled={!!user?.id}>
                          <DeleteIcon
                            name="delete"
                            onClick={() => helper.remove(index - 1)}
                            disabled={!!user?.id}
                          />
                        </DeleteButton>
                      </IndividualTrusteeCard>
                    </div>
                  ))}
                  <Button
                    className={addButtonVariants({
                      hidden:
                        isKybKycNoFailRequested || formik.values.additionalTrustees.length >= 2,
                    })}
                    ref={addButtonRef}
                    onClick={() => handleAddSignatories(helper)}
                  >
                    Add signatories
                    <SVGIcon name="create" />
                  </Button>
                </div>
              )}
            />
          </FormikProvider>
        </div>
      </div>
      <footer className="sticky bottom-0 z-10 flex w-full shrink-0 items-center justify-center bg-white p-2.5">
        <div className="space-x-2.5 rounded-full bg-white p-1.5 shadow-lg">
          <Button
            variant="solid"
            pill
            full={false}
            onClick={handleSubmit}
            disabled={!formik.isValid}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </footer>
    </div>
  );
};
