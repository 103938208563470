import { CloseIcon } from '@paid-ui/icons/close';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { cva } from 'class-variance-authority';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const DrawerRoot = DialogPrimitive.Root;
export const DrawerTrigger = DialogPrimitive.Trigger;
export const DrawerPortal = DialogPrimitive.Portal;
export const DrawerTitle = DialogPrimitive.Title;
export const DrawerDescription = DialogPrimitive.Description;

export type DrawerSide = 'left' | 'right' | 'top' | 'bottom';
export type DrawerRootProps = typeof DrawerRoot;
export type DrawerContentProps = React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
  side?: DrawerSide;
  fullScreen?: boolean;
};

const contentVariants = cva(
  'fixed z-[501] will-change-transform transition-all shadow-lg bg-white text-black',
  {
    variants: {
      side: {
        left: 'inset-y-0 left-0 w-[1240px] max-w-[100vw] data-[state=open]:animate-slideLeftIn data-[state=closed]:animate-slideLeftOut',
        right:
          'inset-y-0 right-0 w-[1240px] max-w-[100vw] data-[state=open]:animate-slideRightIn data-[state=closed]:animate-slideRightOut',
        top: 'inset-x-0 top-0 bottom-[60px] data-[state=open]:animate-slideUpIn data-[state=closed]:animate-slideUpOut',
        bottom:
          'inset-x-0 bottom-0 top-[60px] data-[state=open]:animate-slideDownIn data-[state=closed]:animate-slideDownOut',
      },
      fullScreen: {
        true: '',
      },
    },
    compoundVariants: [
      {
        side: 'left',
        fullScreen: true,
        className: 'w-screen',
      },
      {
        side: 'right',
        fullScreen: true,
        className: ' w-screen',
      },
      {
        side: 'top',
        fullScreen: true,
        className: 'bottom-0',
      },
      {
        side: 'bottom',
        fullScreen: true,
        className: 'top-0',
      },
    ],
  },
);

export const DrawerContent = forwardRef<HTMLDivElement, DrawerContentProps>(
  ({ side = 'right', fullScreen, children, className, ...restProps }, ref) => {
    return (
      <DialogPrimitive.Content
        ref={ref}
        className={twMerge(contentVariants({ side, fullScreen, className }))}
        {...restProps}
      >
        {children}
      </DialogPrimitive.Content>
    );
  },
);

export const DrawerOverlay = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...restProps }, ref) => {
  return (
    <DialogPrimitive.Overlay
      ref={ref}
      className={twMerge(
        'fixed inset-0 z-[401] bg-black/70 data-[state=closed]:animate-fadeOut data-[state=open]:animate-fadeIn',
        className,
      )}
      {...restProps}
    />
  );
});

export const DrawerClose = forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & { preventClose?: boolean }
>(({ hidden, className, children, preventClose, ...restProps }, ref) => {
  if (hidden) {
    return null;
  }

  if (children) {
    return preventClose ? (
      children
    ) : (
      <DialogPrimitive.Close ref={ref} asChild>
        {children}
      </DialogPrimitive.Close>
    );
  }

  return preventClose ? (
    <button
      ref={ref}
      type="button"
      className={twMerge(
        'absolute right-5 top-5 flex h-6 w-6 cursor-pointer items-center justify-center rounded bg-transparent text-grey-dark transition-colors duration-150 ease-linear hover:bg-grey-light hover:text-blue sm:left-[30px]',
        className,
      )}
      {...restProps}
    >
      <CloseIcon className="h-[18px] w-[18px] text-current" />
    </button>
  ) : (
    <DialogPrimitive.Close asChild>
      <button
        ref={ref}
        type="button"
        className={twMerge(
          'absolute right-5 top-5 flex h-6 w-6 cursor-pointer items-center justify-center rounded bg-transparent text-grey-dark transition-colors duration-150 ease-linear hover:bg-grey-light hover:text-blue sm:left-[30px]',
          className,
        )}
        {...restProps}
      >
        <CloseIcon className="h-[18px] w-[18px] text-current" />
      </button>
    </DialogPrimitive.Close>
  );
});

DrawerContent.displayName = 'DrawerContent';
DrawerOverlay.displayName = 'DrawerOverlay';
DrawerClose.displayName = 'DrawerClose';
