import { SVGIcon } from '@paid-ui/icons';
import { Grid, styled } from '@paid-ui/ui';
import { slate } from '@radix-ui/colors';
import { cva } from 'class-variance-authority';

export const CompanySignatoriesCardMilestoneOne = styled(Grid, {
  'alignItems': 'start',
  'gridTemplateAreas': '"firstName" "lastName" "role" "email"',
  'gridTemplateColumns': '1fr',
  'position': 'relative',
  'width': '100%',
  'backgroundColor': '$lightGrey',
  'border': '1px solid $colors$midGrey',
  'borderRadius': '6px',
  'padding': '10px',
  'gap': '14px 10px',
  '@sm': {
    alignItems: 'flex-start',
    gridTemplateAreas: '"firstName lastName role" "email email email"',
    gridTemplateColumns: '160px 160px 1fr',
    gap: '10px',
  },
});

export const CompanySignatoriesCard = styled(Grid, {
  'alignItems': 'start',
  'gridTemplateAreas': '"checkbox firstName deleteButton" ". lastName ." ". role ." ". email ."',
  'gridTemplateColumns': '24px 1fr 24px',
  'position': 'relative',
  'width': '100%',
  'backgroundColor': '$lightGrey',
  'border': '1px solid $colors$midGrey',
  'borderRadius': '6px',
  'padding': '10px',
  'gap': '14px 10px',
  '@sm': {
    alignItems: 'center',
    gridTemplateAreas: '"checkbox firstName lastName role deleteButton" ". email email email ."',
    gridTemplateColumns: '24px 160px 160px 1fr 24px',
    gap: '10px',
  },
});

export const IndividualTrusteeCard = styled(Grid, {
  'alignItems': 'center',
  'gridTemplateAreas': '"firstName deleteButton" "lastName ." "email ."',
  'gridTemplateColumns': '1fr 24px',
  'position': 'relative',
  'width': '100%',
  'backgroundColor': '$lightGrey',
  'border': '1px solid $colors$midGrey',
  'borderRadius': '6px',
  'padding': '10px',
  'gap': '14px 10px',
  '@sm': {
    gridTemplateAreas: '"firstName lastName deleteButton" "email email ."',
    gridTemplateColumns: '1fr 1fr 24px',
    gap: '10px',
  },
});

export const DeleteButton = styled('button', {
  gridArea: 'deleteButton',
  all: 'unset',
  boxSizing: 'border-box',
  size: '24px',
  padding: '1px',
  cursor: 'pointer',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
    disabled: {
      true: {
        pointerEvents: 'none',
      },
    },
  },
});

export const DeleteIcon = styled(SVGIcon, {
  'size': '22px',
  'color': '$fgSecondary',

  '&:hover': {
    color: '$pink',
  },
  'variants': {
    disabled: {
      true: {
        color: slate.slate8,
      },
    },
  },
});

export const gridVariants = cva('grid gap-2', {
  variants: {
    compact: {
      true: 'gap-2',
    },
    hidden: {
      true: 'hidden',
    },
  },
});

export const addButtonVariants = cva('ml-auto mb-[75px] flex items-center gap-1.5 cursor-pointer', {
  variants: {
    hidden: {
      true: 'hidden',
    },
  },
});
