import { BusinessType } from '@paid-ui/constants';
import * as yup from 'yup';

export const permitSchema = yup.object({
  permitName: yup.string().required('Required field'),
  obtainedBy: yup
    .string()
    .required('Obtained by must be required')
    .oneOf(
      Object.values(BusinessType),
      `Obtained by must be one of ${Object.values(BusinessType).join(', ')}`,
    ),
  included: yup.bool(),
  estimatedCost: yup.number().moreThan(-1, 'Estimated cost must not be a negative number'),
});
