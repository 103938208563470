import { AddressState } from '@paid-ui/constants';
import * as yup from 'yup';

export const addressSchema = yup.object({
  unitNumber: yup.string(),
  streetNumber: yup.string().required().label('Street number'),
  streetName: yup.string().required().label('Street name'),
  suburb: yup.string().required().label('Suburb'),
  postcode: yup
    .string()
    .required()
    .matches(/^[\d-]+$/, { excludeEmptyString: true })
    .label('Post code'),
  state: yup.string().required().oneOf(Object.values(AddressState)).label('State'),
  country: yup.string().required().label('Country'),
  manual: yup.bool(),
});
