import isNil from 'lodash/isNil';
import * as yup from 'yup';

export const passwordHelperText =
  'Password should be at least 8 characters and include at least one capital letter, lowercase letter, number and special character.';

export const passwordRequirementText = 'Password does not match the requirements';

export const passwordSchema = yup
  .string()
  .required('Required field')
  .min(8, passwordHelperText)
  .max(128, passwordHelperText)
  .test(
    'with-lowercase',
    passwordHelperText,
    (value) => isNil(value) || (value.match(/[a-z]/g) || []).length > 0,
  )
  .test(
    'with-uppercase',
    passwordHelperText,
    (value) => isNil(value) || (value.match(/[A-Z]/g) || []).length > 0,
  )
  .test(
    'with-digits',
    passwordHelperText,
    (value) => isNil(value) || (value.match(/\d/g) || []).length > 0,
  )
  .test(
    'with-symbols',
    passwordHelperText,
    (value) => isNil(value) || (value.match(/[^\d\sA-Za-z]/g) || []).length > 0,
  );

export const passwordRequirementSchema = yup
  .string()
  .required('Required field')
  .min(8, passwordRequirementText)
  .max(128, passwordRequirementText)
  .test(
    'with-lowercase',
    passwordRequirementText,
    (value) => isNil(value) || (value.match(/[a-z]/g) || []).length > 0,
  )
  .test(
    'with-uppercase',
    passwordRequirementText,
    (value) => isNil(value) || (value.match(/[A-Z]/g) || []).length > 0,
  )
  .test(
    'with-digits',
    passwordRequirementText,
    (value) => isNil(value) || (value.match(/\d/g) || []).length > 0,
  )
  .test(
    'with-symbols',
    passwordRequirementText,
    (value) => isNil(value) || (value.match(/[^\d\sA-Za-z]/g) || []).length > 0,
  );
