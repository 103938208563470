import * as yup from 'yup';

import { userNameSchema } from './user-name';

export const directorSchema = yup.object({
  firstName: userNameSchema,
  lastName: userNameSchema,
  email: yup.string().required().email().label('Email address'),
  confirmEmail: yup
    .string()
    .required()
    .email()
    .equals([yup.ref('email')], 'Confirm email address should be the same.')
    .label('Confirm email address'),
});
