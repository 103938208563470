import { dialogManager } from '@paid-ui/models/dialog';
import { featureManager } from '@paid-ui/models/feature';
import { userManager } from '@paid-ui/models/user';
import { type Address } from '@paid-ui/schemas/zod/address';
import { useToast } from '@paid-ui/ui/toast';
import { request } from '@paid-ui/utils/axios';
import { useMutation } from '@tanstack/react-query';
import { isEmpty, omit, omitBy } from 'lodash';
import { useSnapshot } from 'valtio/react';

import { runAfterSave } from '../_utils';
import {
  type UpdateAdditionalUsersBody,
  useUpdateAdditionalUsers,
} from './update-additional-users';

export type UpdateBusinessProfileBody = {
  abn?: string;
  acn?: string;
  contactNumber?: string;
  registeredName?: string;
  registeredForGst?: boolean; // required
  registeredAddress?: Address;
  builderLicenceNumber?: string;
  earlyReleaseDiscountRate?: number;
  additionalUsers?: UpdateAdditionalUsersBody[];
};

type Response = {
  data: {
    id: string;
  };
};

export const useUpdateBusinessProfile = () => {
  const toast = useToast();
  const {
    userGroupId,
    additionalUsers: users,
    isTrust,
    isKybNoFailRequested,
    provideBuildingServices,
  } = useSnapshot(userManager);
  const { enableEarlyRelease } = useSnapshot(featureManager);
  const { stack } = useSnapshot(dialogManager);

  const { mutate } = useUpdateAdditionalUsers();

  return useMutation({
    mutationKey: ['UpdateBusinessProfile', userGroupId],
    mutationFn: async (body: UpdateBusinessProfileBody) => {
      const { additionalUsers, ...rest } = body;
      let params = { ...rest };
      if (isKybNoFailRequested) {
        params = omit(params, ['abn', 'acn', 'registeredName']);
        // + abn acn registeredName if kyb not requested
      }
      if (!provideBuildingServices) {
        params = omit(params, ['earlyReleaseDiscountRate', 'builderLicenceNumber']);
      }
      if (!enableEarlyRelease) {
        params = omit(params, ['earlyReleaseDiscountRate']);
      }
      if (isTrust) {
        params = omit(params, ['acn']);
      }
      await request.put<Response>(`/user-group/${userGroupId}/business`, {
        ...omitBy(params, (v) => v === ''),
        registeredForGst: true,
      });
      return null;
    },
    onSuccess: (_data, params) => {
      const { additionalUsers } = params;
      if (isEmpty(additionalUsers?.[0]) || users.length > 0) {
        toast.success('Updated successfully.');
        runAfterSave(stack);
      } else {
        mutate(additionalUsers?.[0]);
      }
    },
    onError: (err) => {
      toast.error(err instanceof Error ? err.message : 'Failed to update business profile.');
    },
  });
};
