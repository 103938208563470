import { abnPattern } from '@paid-ui/regexps';
import * as yup from 'yup';

import { addressSchema } from './address';
import { businessNameSchema } from './business-name';

export const businessSchema = yup.object({
  businessName: businessNameSchema,
  abn: yup
    .string()
    .required()
    .matches(abnPattern, {
      message: 'ABN must be 11 digits',
      excludeEmptyString: true,
    })
    .label('ABN'),
  address: addressSchema.required().label('Business address'),
});
