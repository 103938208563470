import { dialogManager } from '@paid-ui/models/dialog';
import { userManager } from '@paid-ui/models/user';
import { type Address } from '@paid-ui/schemas/zod/address';
import { useToast } from '@paid-ui/ui/toast';
import { request } from '@paid-ui/utils/axios';
import { useMutation } from '@tanstack/react-query';
import { isEmpty, omitBy } from 'lodash';
import { useSnapshot } from 'valtio/react';

import { runAfterSave } from '../_utils';
import {
  type UpdateAdditionalUsersBody,
  useUpdateAdditionalUsers,
} from './update-additional-users';

export type UpdateTrusteeProfileBody = {
  acn?: string;
  registeredName?: string;
  registeredAddress: Address;
  additionalUsers?: UpdateAdditionalUsersBody[];
};

type Response = {
  data: {
    id: string;
  };
};

export const useUpdateTrusteeProfile = () => {
  const toast = useToast();
  const { trusteeGroupId, additionalUsers: users } = useSnapshot(userManager);
  const { mutate } = useUpdateAdditionalUsers();
  const { stack } = useSnapshot(dialogManager);

  return useMutation({
    mutationKey: ['UpdateTrusteeProfile', trusteeGroupId],
    mutationFn: async (body: UpdateTrusteeProfileBody) => {
      const { additionalUsers, ...rest } = body;
      await request.put<Response>(`/user-group/${trusteeGroupId}/business`, {
        ...omitBy(rest, (v) => v === ''),
        registeredForGst: true,
      });
      return null;
    },
    onSuccess: (_data, params) => {
      const { additionalUsers } = params;
      if (isEmpty(additionalUsers?.[0]) || users.length > 0) {
        toast.success('Updated successfully.');
        runAfterSave(stack);
      } else {
        mutate(additionalUsers?.[0]);
      }
    },
    onError: (err) => {
      toast.error(err instanceof Error ? err.message : 'Failed to update business profile.');
    },
  });
};
