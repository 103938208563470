import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

export enum FlyoutType {
  NONE = 'NONE',

  ACCOUNT_NOT_READY_ALERT = 'ACCOUNT_NOT_READY_ALERT',
  APPLY_ACCOUNT = 'APPLY_ACCOUNT',
  ACCOUNT_TRANSFER = 'ACCOUNT_TRANSFER',
  ASSIGN_FUNDS = 'ASSIGN_FUNDS',
  TRANSACTION_DETAILS = 'TRANSACTION_DETAILS',

  CREATE_PROJECT = 'CREATE_PROJECT',
  EDIT_PROJECT = 'EDIT_PROJECT',
  ARCHIVE_PROJECT = 'ARCHIVE_PROJECT',
  DELETE_PROJECT = 'DELETE_PROJECT',

  JOIN_CONTRACT = 'JOIN_CONTRACT',
  CREATE_CONTRACT = 'CREATE_CONTRACT',
  AMEND_CONTRACT = 'AMEND_CONTRACT',
  REVIEW_CONTRACT = 'REVIEW_CONTRACT',
  SIGN_CONTRACT = 'SIGN_CONTRACT',
  ABANDON_CONTRACT = 'ABANDON_CONTRACT',
  RESEND_INVITE = 'RESEND_INVITE',
  CONTRACT_DETAILS = 'CONTRACT_DETAILS',
  CONTRACT_NAVIGATOR = 'CONTRACT_NAVIGATOR',

  SET_COMMENCEMENT_DATE = 'SET_COMMENCEMENT_DATE',
  SET_COMPLETION_DATE = 'SET_COMPLETION_DATE',

  LINKED_PAYMENTS = 'LINKED_PAYMENTS',
  PAYMENT_DETAILS = 'PAYMENT_DETAILS',

  NEW_CLAIM = 'NEW_CLAIM',
  EDIT_CLAIM = 'EDIT_CLAIM',
  UPDATE_CLAIM = 'UPDATE_CLAIM',
  AMEND_CLAIM = 'AMEND_CLAIM',
  REVIEW_CLAIM = 'REVIEW_CLAIM',
  PAY_CLAIM = 'PAY_CLAIM',
  ALL_CLAIMS = 'ALL_CLAIMS',
  CLAIM_DETAILS = 'CLAIM_DETAILS',

  NEW_VARIATION = 'NEW_VARIATION',
  AMEND_VARIATION = 'AMEND_VARIATION',
  REVIEW_VARIATION = 'REVIEW_VARIATION',
  ABANDON_VARIATION = 'ABANDON_VARIATION',
  ALL_VARIATIONS = 'ALL_VARIATIONS',
  VARIATION_DETAILS = 'VARIATION_DETAILS',

  ABANDON_ADJUSTMENT = 'ABANDON_ADJUSTMENT',
  ADJUSTMENT_DETAILS = 'ADJUSTMENT_DETAILS',

  SECURE_PAYMENT = 'SECURE_PAYMENT',
  SECURE_VARIATION = 'SECURE_VARIATION',
  EARLY_RELEASE = 'EARLY_RELEASE',

  HELP_CENTER = 'HELP_CENTER',
  CHECKOUT = 'CHECKOUT',

  NEW_CLAIM_RETENTION = 'NEW_CLAIM_RETENTION',
  REVIEW_RETENTION_CLAIM = 'REVIEW_RETENTION_CLAIM',
  RETENTION_CLAIM_DETAILS = 'RETENTION_CLAIM_DETAILS',

  NEW_LINKING = 'NEW_LINKING',

  SUPER_CONTRACT = 'SUPER_CONTRACT',
  SUPERCONTRACT = 'SUPERCONTRACT',
}

export enum NestedFlyoutType {
  NONE = 'NONE',

  VERIFY_MOBILE = 'VERIFY_MOBILE',
  ADOPT_SIGNATURE = 'ADOPT_SIGNATURE',

  EDIT_TRUSTEE = 'EDIT_TRUSTEE',
  EDIT_PERSONAL = 'EDIT_PERSONAL',
  EDIT_BUSINESS = 'EDIT_BUSINESS',
  EDIT_INDIVIDUAL_TRUSTEE = 'EDIT_INDIVIDUAL_TRUSTEE',

  SECUREPAID_DOC = 'SECUREPAID_DOC',
  EVIDENCE_MAP_VIEW = 'EVIDENCE_MAP_VIEW',
  RETENTION_CLAIM_DETAILS = 'RETENTION_CLAIM_DETAILS',
  CLAIM_DETAILS_WITH_RETENTION = 'CLAIM_DETAILS_WITH_RETENTION',
}

export type FlyoutState = {
  type: FlyoutType;
  pathname: string;
  search?: string;
  data?: Record<string, unknown> | null;
};

export const initialState = {
  open: false,
  className: '',
  showCloseIcon: false,
  nestedOpen: false,
  nestedType: NestedFlyoutType.NONE,
  nestedDetails: {} as Record<string, unknown>,
  type: FlyoutType.NONE,
  closeOnClickOutside: false,
  closeOnEscapeKeyDown: true,
  stack: [] as FlyoutState[],
  details: {} as Record<string, unknown>,
  closeCallback: () => {},
};

export const flyoutManager = proxy(initialState);

devtools(flyoutManager, {
  name: 'Global Flyout',
  enabled: false,
});
