import { abnPattern, acnPattern } from '@paid-ui/regexps';
import * as yup from 'yup';

import { addressSchema } from './address';
import { businessNameSchema } from './business-name';
import { userNameSchema } from './user-name';

export const trustSchema = yup.object({
  businessName: businessNameSchema,
  abn: yup
    .string()
    .matches(abnPattern, {
      message: 'ABN must be 11 digits',
      excludeEmptyString: true,
    })
    .label('ABN'),
  address: addressSchema.required().label('Trust address'),
});

export const trusteeCompanySchema = yup.object({
  businessName: businessNameSchema,
  acn: yup
    .string()
    .required()
    .matches(acnPattern, {
      message: 'ACN must be 9 digits',
      excludeEmptyString: true,
    })
    .label('ACN'),
  address: addressSchema.required().label('Trustee company address'),
});

export const trusteeSchema = yup.object({
  firstName: userNameSchema.optional(),
  lastName: userNameSchema.optional(),
  email: yup.string().email().label('Email address'),
  confirmEmail: yup
    .string()
    .email()
    .equals([yup.ref('email')], 'Confirm email address should be the same.')
    .label('Confirm email address'),
});

export const trusteeSchema2 = yup.object({
  firstName: userNameSchema,
  lastName: userNameSchema,
  email: yup.string().required().email().label('Email address'),
  confirmEmail: yup
    .string()
    .required()
    .email()
    .equals([yup.ref('email')], 'Confirm email address should be the same.')
    .label('Confirm email address'),
});
