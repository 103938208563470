import { dialogManager } from '@paid-ui/models/dialog';
import { userManager } from '@paid-ui/models/user';
import { useToast } from '@paid-ui/ui/toast';
import { request } from '@paid-ui/utils/axios';
import { useMutation } from '@tanstack/react-query';
import { useSnapshot } from 'valtio/react';

import { runAfterSave } from '../_utils';

type UpdateTrusteesInfoBody = {
  trustId: string;
  additionalTrustees: Array<{
    firstName: string;
    lastName: string;
    email: string;
  }>;
};

export const useUpdateTrusteesInfo = () => {
  const toast = useToast();
  const { stack } = useSnapshot(dialogManager);
  const { isKybKycNoFailRequested } = useSnapshot(userManager);

  return useMutation({
    retry: false,
    mutationFn: async (body: UpdateTrusteesInfoBody) => {
      const { trustId, additionalTrustees } = body;
      if (isKybKycNoFailRequested || additionalTrustees.length === 0) return null;
      const { data } = await request.put<null>(`/trusts/${trustId}/trustee/individual`, {
        additionalTrustees,
      });
      return data;
    },
    onSuccess(_, { additionalTrustees }) {
      if (!isKybKycNoFailRequested && additionalTrustees.length > 0) {
        toast.success('Update successfully.');
      }
      runAfterSave(stack);
    },
    onError(err) {
      toast.error(err instanceof Error ? err.message : 'Failed to update trustees information');
    },
  });
};
