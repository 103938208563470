import * as yup from 'yup';

export const primeCostItemSchema = yup.object({
  description: yup.string().required('Required field'),
  builderAllowanceUnitCost: yup
    .number()
    .required('Required field')
    .positive('Must be a positive number'),
  quantity: yup
    .number()
    .required('Required field')
    .integer('Quantity must be an integer')
    .positive('Must be a positive number'),
  builderMargin: yup.number().moreThan(-1, 'Builder margin must not be a negative number'),
});

export const provisionalSumAllowanceSchema = yup.object({
  description: yup.string().required('Required field'),
  builderAllowanceUnitCost: yup
    .number()
    .required('Unit cost allowance is required')
    .positive('Must be a positive number'),
  quantity: yup
    .number()
    .required('Required field')
    .integer('Quantity must be an integer')
    .positive('Must be a positive number'),
  builderMargin: yup.number().moreThan(-1, 'Builder margin must not be a negative number'),
});

export const adjustmentItemSchema = yup.object({
  id: yup.string().required('Required field').uuid('Adjustment item id must be uuid'),
  description: yup.string().required('Required field'),
  existingUnitCost: yup
    .number()
    .required('Required field')
    .min(0, 'Original unit cost must not be a negative number'),
  existingQuantity: yup
    .number()
    .required('Required field')
    .integer('Original quantity must be an integer')
    .min(0, 'Original quantity must not be a negative number'),
  adjustedUnitCost: yup.number().min(0, 'Unit cost must not be a negative number'),
  adjustedQuantity: yup
    .number()
    .integer('Quantity must be an integer')
    .min(0, 'Quantity must not be a negative number'),
  selected: yup.bool(),
});
