import { PriceVariationMethod, WorksCategory } from '@paid-ui/constants';
import * as yup from 'yup';

export const variationItemSchema = yup.object({
  relatedWorks: yup
    .string()
    .required('Required field')
    .oneOf(
      Object.values(WorksCategory),
      `Related works must be one of ${Object.values(WorksCategory).join(', ')}.`,
    ),
  priceImpact: yup
    .string()
    .required('Required field')
    .oneOf(
      Object.values(PriceVariationMethod),
      `Price impact must be one of ${Object.values(PriceVariationMethod).join(', ')}.`,
    ),
  variationItemPrice: yup
    .number()
    .required('Required field')
    .min(0, 'Variation item price must be not a negative number'),
  description: yup.string().required('Required field'),
});
