import { useUpdateTrusteeProfile } from '@paid-ui/blocks/settings/mutations/update-trustee-profile';
import { Button } from '@paid-ui/components/button';
import { DrawerClose } from '@paid-ui/components/drawer';
import { type BusinessStructure, defaultAddress } from '@paid-ui/constants';
import { closeDialog } from '@paid-ui/models/dialog';
import { userManager } from '@paid-ui/models/user';
import { acnPattern } from '@paid-ui/regexps';
import { addressSchema, businessNameSchema, userNameSchema } from '@paid-ui/schemas';
import { type Address, type UserInfoForBusiness } from '@paid-ui/types';
import { Input, Logo, PlaceAutocomplete, RadioGroup, Separator, useToast } from '@paid-ui/ui';
import { formatAddress } from '@paid-ui/utils';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useSnapshot } from 'valtio/react';
import * as yup from 'yup';

import { CompanySignatoriesCardMilestoneOne, gridVariants } from './_Base';

const initSignatories = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
};

export const EditTrusteesInfo = () => {
  const toast = useToast();
  const {
    businessStructure,
    isKybNoFailRequested,
    profile,
    acn,
    isTrust,
    trust,
    additionalUsers,
    currentUser,
    hasMultipleUsers,
    isIndividualTrust,
  } = useSnapshot(userManager);

  const { mutate, isLoading } = useUpdateTrusteeProfile();

  const formik = useFormik({
    initialValues: {
      acn: '',
      registeredName: '',
      registeredAddress: defaultAddress as Address | undefined,
      hasMultipleUsers: false,
      currentUser: undefined as UserInfoForBusiness | undefined,
      additionalUsers: [] as UserInfoForBusiness[],
      isKybKycRequested: false,
    },
    validationSchema: yup.object({
      acn: yup.string().required('Required field').matches(acnPattern, {
        message: 'ACN must be 9 digits',
        excludeEmptyString: true,
      }),
      registeredName: businessNameSchema,
      registeredAddress: addressSchema.required('Required field'),
      additionalUsers: yup
        .array(
          yup.object({
            firstName: userNameSchema,
            lastName: userNameSchema,
            email: yup.string().required().email().label('Email address'),
          }),
        )
        .when(['hasMultipleUsers', 'isKybKycRequested'], {
          is: (hasMultipleUsers: boolean) => hasMultipleUsers && !isKybNoFailRequested,
          then: (schema) => schema.min(1),
        }),
    }),
    onSubmit(values) {
      if (!profile || !trust?.id) {
        return;
      }
      mutate({
        acn: values.acn,
        registeredName: values.registeredName,
        registeredAddress: omit(values.registeredAddress, ['manual']),
        additionalUsers: values.additionalUsers.map((user) => {
          return {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
          };
        }),
      });
    },
  });

  const handleAddressTouched = useCallback(() => {
    formik.setFieldTouched('registeredAddress', !!formik.errors.registeredAddress);
  }, [formik]);

  const handleSubmit = useCallback(() => {
    formik.submitForm();
  }, [formik]);

  const getSignatoriesErrors = useCallback(
    (itemIndex: number, field: keyof typeof initSignatories) => {
      return formik.touched.additionalUsers?.[itemIndex]?.[field]
        ? // @ts-expect-error missing types
          formik.errors.additionalUsers?.[itemIndex]?.[field]
        : undefined;
    },
    [formik.errors.additionalUsers, formik.touched.additionalUsers],
  );

  const disableAddSignatories = useMemo(() => {
    return additionalUsers?.length > 0 || trust?.businessSetupCompleted;
  }, [additionalUsers?.length, trust?.businessSetupCompleted]);

  const renderAdditionalSignatories = useCallback(() => {
    return (
      <CompanySignatoriesCardMilestoneOne>
        <Input
          block
          required
          disabled={disableAddSignatories}
          css={{ gridArea: 'firstName' }}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          name="additionalUsers.0.firstName"
          value={formik.values.additionalUsers?.[0]?.firstName}
          placeholder="First name *"
          errorMessage={getSignatoriesErrors(0, 'firstName')}
        />
        <Input
          block
          required
          name="additionalUsers.0.lastName"
          css={{ gridArea: 'lastName' }}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          disabled={disableAddSignatories}
          value={formik.values.additionalUsers?.[0]?.lastName}
          placeholder="Last name *"
          errorMessage={getSignatoriesErrors(0, 'lastName')}
        />
        <Input block required disabled name="role" css={{ gridArea: 'role' }} value={'Director'} />
        <Input
          block
          required
          name="additionalUsers.0.email"
          css={{ gridArea: 'email' }}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          disabled={disableAddSignatories}
          value={formik.values.additionalUsers?.[0]?.email}
          placeholder="Email *"
          errorMessage={getSignatoriesErrors(0, 'email')}
        />
      </CompanySignatoriesCardMilestoneOne>
    );
  }, [
    disableAddSignatories,
    formik.handleBlur,
    formik.handleChange,
    formik.values.additionalUsers,
    getSignatoriesErrors,
  ]);

  useEffect(() => {
    if (profile) {
      formik.setValues({
        acn,
        isKybKycRequested: isKybNoFailRequested,
        hasMultipleUsers,
        registeredName: trust?.registeredName ?? '',
        registeredAddress: trust?.registeredAddress,
        additionalUsers,
        currentUser,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.id]);

  useEffect(() => {
    if (!isTrust || isIndividualTrust) {
      toast.error('No permission');
      closeDialog();
    }
  }, [isIndividualTrust, isTrust, toast]);

  return (
    <div className="flex h-full w-full flex-col overflow-y-auto">
      <header className="sticky top-0 z-10 flex w-full shrink-0 flex-col items-center justify-center gap-[14px] border-b border-grey-mid bg-white p-5 sm:rounded-t-2xl">
        <DrawerClose />
        <div className="flex items-center gap-2">
          <Logo symbolOnly width={25} height={25} />
          <div className="text-center text-xl font-semibold">Edit trustee</div>
        </div>
      </header>
      <div className="flex flex-1 flex-col gap-6 px-5 py-[18px]">
        <form
          className="flex w-full flex-col gap-6"
          onSubmit={formik.handleSubmit}
          onReset={formik.handleReset}
        >
          <div className="relative grid w-full grid-cols-1 gap-y-9 sm:grid-cols-2 sm:gap-x-5">
            <RadioGroup
              showIcon
              required
              disabled
              classic={false}
              label="Type"
              labelSize={16}
              labelWeight="medium"
              name="businessStructure"
              value={businessStructure}
              options={[
                {
                  value: businessStructure as BusinessStructure,
                  label: 'Company',
                  color: 'primary',
                },
              ]}
            />
          </div>
          <Input
            required
            size="large"
            maxLength={60}
            labelSize={16}
            readOnly={isKybNoFailRequested}
            labelWeight="medium"
            name="registeredName"
            label="Business name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.registeredName}
            errorMessage={
              formik.touched.registeredName && Boolean(formik.errors.registeredName)
                ? formik.errors.registeredName
                : undefined
            }
            block
          />
          <div className="relative grid w-full grid-cols-1 gap-y-9 sm:grid-cols-2 sm:gap-x-5">
            <Input
              block
              required
              size="large"
              labelSize={16}
              maxLength={30}
              name="acn"
              label="ACN"
              labelWeight="medium"
              format="### ### ###"
              disabled={isKybNoFailRequested}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.acn}
              errorMessage={
                formik.touched.acn && Boolean(formik.errors.acn) ? formik.errors.acn : undefined
              }
            />
          </div>
          <PlaceAutocomplete
            required
            size="large"
            maxLength={60}
            preview={false}
            label="Company address"
            name="registeredAddress"
            value={formik.values.registeredAddress}
            placeholder="Please enter keyword to search address"
            onChange={(value) => formik.setFieldValue('registeredAddress', value)}
            onBlur={handleAddressTouched}
            touched={formik.touched.registeredAddress}
            errorMessage={formik.errors.registeredAddress}
          />
          <div className="grid gap-6">
            <div className="flex items-center gap-6">
              <div className="shrink-0 text-xl font-semibold">Company signatories</div>
              <Separator decorative />
            </div>
            <div className="hidden text-base text-grey-dark">
              Unless you are both a company director and secretary an additional signatory must be
              added.
            </div>
            <div className="grid gap-2">
              <span className="text-base font-medium">Me</span>
              <CompanySignatoriesCardMilestoneOne>
                <Input
                  block
                  required
                  disabled
                  name="firstName"
                  css={{ gridArea: 'firstName' }}
                  value={formik.values.currentUser?.firstName}
                />
                <Input
                  block
                  required
                  disabled
                  name="lastName"
                  css={{ gridArea: 'lastName' }}
                  value={formik.values.currentUser?.lastName}
                />
                <Input
                  block
                  required
                  disabled
                  name="role"
                  css={{ gridArea: 'role' }}
                  value={hasMultipleUsers ? 'Director and secretory' : 'Director'}
                />
              </CompanySignatoriesCardMilestoneOne>
            </div>
            <div
              className={gridVariants({
                compact: true,
                hidden: !hasMultipleUsers,
              })}
            >
              <span className="text-base font-medium">Current additional signatory</span>
              {renderAdditionalSignatories()}
            </div>
          </div>
        </form>
      </div>
      <footer className="sticky bottom-0 z-10 flex w-full shrink-0 items-center justify-center bg-white p-2.5">
        <div className="space-x-2.5 rounded-full bg-white p-1.5 shadow-lg">
          <Button
            variant="solid"
            pill
            full={false}
            onClick={handleSubmit}
            disabled={!formik.isValid || !formatAddress(formik.values.registeredAddress)}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </footer>
    </div>
  );
};
