import * as yup from 'yup';

export const utilitySchema = yup.object({
  name: yup.string().required('Required field'),
  customName: yup
    .string()
    .max(13, 'Service name must be equal or less than 13 characters')
    .when('name', {
      is: (value: string) => value === 'Other',
      then: (schema) => schema.required('Required field'),
    }),
  included: yup.bool(),
  estimatedCost: yup.number().moreThan(-1, 'Estimated cost must not be a negative number'),
});
