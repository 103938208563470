import * as yup from 'yup';

export const attachmentSchema = yup.object({
  fileName: yup.string().required().label('File name'),
  fileAddress: yup.string().required().label('File address'),
  fileSize: yup.number().positive().label('File size'),
  fileType: yup.string(),
  category: yup.string(),
});

export const attachmentMapSchema = yup.object({
  SPECIAL_CONDITIONS: yup.array(attachmentSchema),
  SPECIFICATION: yup.array(attachmentSchema),
  DESIGN_DRAWINGS: yup.array(attachmentSchema),
  ENGINEERING_DRAWINGS: yup.array(attachmentSchema),
  FOUNDATION_REPORTS: yup.array(attachmentSchema),
  PURCHASE_ORDER: yup.array(attachmentSchema),
  OTHER: yup.array(attachmentSchema),
});
